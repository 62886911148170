/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Okolí"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-dx93aa pb--60 pt--80" name={"uvod-1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/25609/87d6775ad266403faa6a5920a1a4b405_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/25609/87d6775ad266403faa6a5920a1a4b405_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/25609/87d6775ad266403faa6a5920a1a4b405_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/25609/87d6775ad266403faa6a5920a1a4b405_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/25609/87d6775ad266403faa6a5920a1a4b405_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/25609/87d6775ad266403faa6a5920a1a4b405_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/25609/87d6775ad266403faa6a5920a1a4b405_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/25609/87d6775ad266403faa6a5920a1a4b405_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 pb--10 pt--10 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Vojtěchov</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--50" name={"lyze"} style={{"paddingBottom":38,"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left" content={"Lyže"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"bj5ye8712o9"} style={{"marginTop":0,"paddingTop":0,"paddingBottom":50}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"Ski areál Harusův kopec – 9,5km (od chalupy)"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"U Nového Města na Moravě na území&nbsp;<span style=\"font-weight: bold;\">Českomoravské vrchoviny</span>&nbsp;se ve svazích&nbsp;<span style=\"font-weight: bold;\">Harusova kopce</span>&nbsp;v nadmořské výšce 734 metrů nachází malé lyžařské středisko.\n              "}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"font-weight: bold;\">Ski Harusák</span>&nbsp;nabízí&nbsp;<span style=\"font-weight: bold;\">550 metrů</span>&nbsp;dlouhou&nbsp;<span style=\"font-weight: bold;\">sjezdovku</span>, na jejíž vrchol vyváží lyžaře a snowboardisty lanová dráha. Svah je technicky zasněžovaný a denně upravovaný rolbou, umělé osvětlení pak přeje skvělému&nbsp;<span style=\"font-weight: bold;\">večernímu lyžování.              </span>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"Děti si užijí spoustu legrace na 30 metrů dlouhém laně, u něhož se nachází také&nbsp;<span style=\"font-weight: bold;\">lyžařská škola</span>. Samozřejmostí je i&nbsp;<span style=\"font-weight: bold;\">půjčovna</span>&nbsp;se servisem a testcentrem.<br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"V okolí Nového Města je upravováno na&nbsp;<span style=\"font-weight: bold;\">100 kilometrů běžeckých tras</span>, a to rolbou pro klasické lyžování. Kdo dává přednost bruslení, může vyrazit na 4kilometrový Příměstský okruh.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/25609/6e6f311dd4724f8985cc16eeb458675c_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/25609/6e6f311dd4724f8985cc16eeb458675c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25609/6e6f311dd4724f8985cc16eeb458675c_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"bj5ye8712o9"} style={{"paddingTop":0,"paddingBottom":48}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"Ski areál Jimramov – 15 km od chalupy"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"V obci Nový Jimramov ve východní části&nbsp;<span style=\"font-weight: bold;\">Žďárských vrchů</span>&nbsp;leží v nadmořské výšce 650 metrů nevelké lyžařské středisko.&nbsp;<span style=\"font-weight: bold;\">Ski areál Jimramov</span>&nbsp;nabízí milovníkům zimních sportů&nbsp;<span style=\"font-weight: bold;\">několik sjezdovek</span>&nbsp;- čtyři modré pro začátečníky, dvě červené pro středně pokročilé a černou, s výstižným názvem Lucifer, pro zkušené lyžaře.              "}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"Na technicky zasněžované svahy vyváží sportovce&nbsp;<span style=\"font-weight: bold;\">dva vleky</span>&nbsp;a umělé osvětelní umožňuje pořádání&nbsp;<span style=\"font-weight: bold;\">večerního lyžování</span>. Na snowboardisty čeká&nbsp;<span style=\"font-weight: bold;\">snowpark</span>&nbsp;se skoky a rampami, na nejmenší návštěvníky potom&nbsp;<span style=\"font-weight: bold;\">školička s dětským lanem</span>.              "}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"V Jimramově jsou k dispozici také&nbsp;<span style=\"font-weight: bold;\">lyžařská škola a půjčovna</span>&nbsp;vybavení se servisem, komu vyhládne, může se&nbsp;<span style=\"font-weight: bold;\">občerstvit</span>&nbsp;přímo na sjezdovce.              "}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"Přímo Jimramovem prochází bezmála&nbsp;<span style=\"font-weight: bold;\">16 kilometrů</span>&nbsp;dlouhý&nbsp;<span style=\"font-weight: bold;\">Jimramovský</span> <span style=\"font-weight: bold;\">běžecký okruh</span>, kromě něho pak příznivci běžkování mohou využít také další desítky kilometrů značených tras napříč Žďárskými vrchy.\n              "}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/2124/4326f2caf1cb479ca2d705fc020ab133_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/2124/4326f2caf1cb479ca2d705fc020ab133_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/4326f2caf1cb479ca2d705fc020ab133_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/4326f2caf1cb479ca2d705fc020ab133_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"bj5ye8712o9"} style={{"paddingTop":0,"paddingBottom":4}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"Ski areál Dalečín – 18 km od chalupy\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"V Dalečíně severně od Bystřice nad Pernštejnem se v podhůří&nbsp;<span style=\"font-weight: bold;\">Českomoravské vrchoviny</span>&nbsp;v nadmořské výšce 645 metrů rozkládá stejnojmenné lyžařské středisko.&nbsp;<span style=\"font-weight: bold;\">Ski areál Dalečín</span>&nbsp;si pro příznivce zimních sportů připravil&nbsp;<span style=\"font-weight: bold;\">500-600 metrů</span>&nbsp;dlouhou&nbsp;<span style=\"font-weight: bold;\">sjezdovku</span>&nbsp;s převýšením 105 metrů, která je vhodná jak pro začínající, tak pokročilé lyžaře.              "}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"Svah je technicky zasněžovaný a uměle osvětlený, a tak si tady na své přijdou i milovníci&nbsp;<span style=\"font-weight: bold;\">večerního lyžování</span>.              "}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"V areálu je k dispozici&nbsp;<span style=\"font-weight: bold;\">půjčovna vybavení</span>, o hladové žaludky sportovů se pak starají&nbsp;<span style=\"font-weight: bold;\">občerstvení</span>&nbsp;s vyhřívanou místností i bufet s venkovním posezením. Řidiči mohou parkovat zdarma přímo u vleku.              "}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"Kdo dává přednost&nbsp;<span style=\"font-weight: bold;\">běžkování</span>, může se za příznivých sněhových podmínek těšit na upravované trasy, které je zavedou až do Nového Města na Moravě a Jimramova.              "}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/2124/7123983801754855b0b8b824aa246422_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/2124/7123983801754855b0b8b824aa246422_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/7123983801754855b0b8b824aa246422_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"kolo"} style={{"paddingTop":38,"paddingBottom":30}}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left" content={"Kolo"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--18 w--500" content={"Okolí chalupy nabízí mnoho zajímavých variant na výlety na kole. Inspirací může být trasa: \n"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"bj5ye8712o9"} style={{"paddingTop":0,"paddingBottom":44}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"1/ \n \n\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Lísek - Kolonie Marie - Vlčí kámen - Kamenice, vyhl. - Koníkov - Metodka - Odranec - Kuklík - Sněžné - Krátká - Samotín - Dráteničky - Malinská skála - Devět skal - Herálec - Krucemburk - rybník Řeka, cca 58 km               "}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"<a href=\"https://alena.ilcik.cz/1707-vysocina.php\" style=\"color: rgb(12, 27, 241);\">https://alena.ilcik.cz/1707-vysocina.php              </a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"2/ \n \n\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Lísek – Koníkov – Zuberský rybník – Nové Město na Moravě – Vysočina Aréna – Nová Ves – Dlouhé – Račice – Olešínky – Zvole – Horní Rožínka – Rodkov – Vojetín –&nbsp;EDEN farma – Bystřice n. P. cca 45km               "}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"<a href=\"https://www.turistika.cz/trasy/zubri-stezky-za-sportem/detail\" style=\"color: rgb(12, 27, 241);\">https://www.turistika.cz/trasy/zubri-stezky-za-sportem/detail              </a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"pamatky"} style={{"marginTop":0,"paddingTop":2,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left" content={"Památky\n"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"bj5ye8712o9"} style={{"paddingTop":34,"paddingBottom":56}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"Zřícenina hradu Dalečín – 17,5 km od chalupy\n\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Zřícenina hradu Dalečín&nbsp;<span style=\"font-weight: bold;\">stojí uprostřed obce Dalečín</span>. Byl postaven na kopci nad řekou Svratkou."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"První písemná zpráva je kladena do 14. století. Společně s hradem Skály&nbsp;<span style=\"font-weight: bold;\">měl být rozbořen jako sídlo lupičů</span>, poté jej páni z Pernštejna obnovili, ale jeho další osudy nejsou známy. Dobytí a&nbsp;<span style=\"font-weight: bold;\">zboření hradu vojsky moravského hejtmana</span>&nbsp;se neopírá o žádný věrohodný pramen, jde spíše o nepřesný výklad barokních historiků."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"Dnes jsou&nbsp;<span style=\"font-weight: bold;\">zbytky hradu upraveny jako park</span>. Zachovala se mohutná část zdiva se střílnami a okny obytných místností, vybavených ve výklencích sedátky. Na zdi lze rozeznat tři podlaží.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/2124/7417cb1c8efb4773a835bc19e53153f7_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/2124/7417cb1c8efb4773a835bc19e53153f7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/7417cb1c8efb4773a835bc19e53153f7_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"bj5ye8712o9"} style={{"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"Zřícenina hradu Štarkov u Jimramova – 16,5 km od chalupy\n\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"font-weight: bold;\">Štarkov (též Skály) je zřícenina hradu ze 14. století</span>&nbsp;poblíž Nového Jimramova. Za husitských válek se zde&nbsp;<span style=\"font-weight: bold;\">schovával oddíl 500 husitů, které odtud chtěl vypudit vévoda Albrecht</span>, což se mu ale nepodařilo. Poté se Štarkov stal sídlem loupeživého rytíře.&nbsp;<span style=\"font-weight: bold;\">Kvůli loupeživým tlupám, které se usídlily na hradě</span>, byl Štarkov v 15. století vykoupen a pobořen. Hrad už nikdo neobnovil a ten postupně pustl."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"Do současnosti se dochovaly pozůstatky hradebních zdí a dalších staveb. V hradním jádře&nbsp;<span style=\"font-weight: bold;\">probíhá archeologický průzkum, ale zřícenina je volně přístupná</span>.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/25609/ddb080ea222f468f81b60f5c2eb09f46_s=660x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":465}} srcSet={"https://cdn.swbpg.com/t/25609/ddb080ea222f468f81b60f5c2eb09f46_s=350x_.JPG 350w, https://cdn.swbpg.com/t/25609/ddb080ea222f468f81b60f5c2eb09f46_s=660x_.JPG 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"bj5ye8712o9"} style={{"paddingTop":50,"paddingBottom":55}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"Zřícenina hradu Zubštejn – 15,5 km od chalupy\n\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"font-weight: bold;\">Zubštejn je zřícenina jednoho z největších hradů na Moravě.</span> Leží nad vsí Pivonice - městkou částí Bystřice nad Pernštejnem. Hrad byl založen ve 14. století a&nbsp;<span style=\"font-weight: bold;\">po dobu své historie často měnil majitele</span>. Na rozdíl od okolních hradů byl obydlen až do 16. století, ale poté jej majitelé opustili a on zpustnul."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"*Zřícenina hradu je poměrně zachovalá*a dochována jsou dokonce i některá patra paláce. Je<span style=\"font-weight: bold;\">&nbsp;volně přístupná</span>&nbsp;a vedou k ní značené turistické stezky. U zříceniny si&nbsp;<span style=\"font-weight: bold;\">můžete prohlédnout dřevěné modely válečných strojů</span>&nbsp;a konají se zde také různé kulturní akce.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/2124/baefda19d11c40e0a588b6c3ef61da95_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/2124/baefda19d11c40e0a588b6c3ef61da95_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/baefda19d11c40e0a588b6c3ef61da95_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/baefda19d11c40e0a588b6c3ef61da95_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"bj5ye8712o9"} style={{"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"Hrad Svojanov – 30 km od chalupy\n\n\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Takzvaný&nbsp;<span style=\"font-weight: bold;\">\"Knížecí hrad\" (Fürstenberg)</span>, nazývaný také&nbsp;<span style=\"font-weight: bold;\">\"vila českých královen\"</span>, byl vystavěn ve druhé polovině&nbsp;<span style=\"font-weight: bold;\">13. století Přemyslem Otakarem II.</span>, později byl renesančně a nakonec empírově přestavěn a dnes patří městu Polička, které ho zpřístupňuje veřejnosti."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"Návštěvníci se na Svojanově mohou těšit na&nbsp;<span style=\"font-weight: bold;\">prohlídku interiérů</span>&nbsp;s instalacemi, které přiblíží život na hradě ve 2. polovině 19. století. Zájemci se podívají do sklepení nebo jedinečného domu zbrojnošů v goticko-renesančním stylu.&nbsp;"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"K dispozici jsou&nbsp;<span style=\"font-weight: bold;\">dva prohlídkové okruhy</span>. Ten první míří do empírového paláce s replikou&nbsp;<span style=\"font-weight: bold;\">pohřební královské koruny</span>&nbsp;Přemysla Otakara II. nebo na vyhlídkovou terasu. Druhý okruh pak provede hosty sklepeními,&nbsp;<span style=\"font-weight: bold;\">expozicí pověstí a legend hradu</span>, mučírnou, lapidáriem a domem zbrojnošů.&nbsp;"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"Samostatně si návštěvníci mohou prohlédnout&nbsp;<span style=\"font-weight: bold;\">exteriéry</span>&nbsp;hradu a kdo chce, může vystoupat na&nbsp;<span style=\"font-weight: bold;\">věž</span>, odkud jsou hezké výhledy do okolí.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/2124/5f1c1255e5ad4bd0845b0e10be1c34ce_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/2124/5f1c1255e5ad4bd0845b0e10be1c34ce_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/5f1c1255e5ad4bd0845b0e10be1c34ce_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/5f1c1255e5ad4bd0845b0e10be1c34ce_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/5f1c1255e5ad4bd0845b0e10be1c34ce_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"bj5ye8712o9"} style={{"paddingTop":48,"paddingBottom":54}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"Hrad Pernštejn – 24 km od chalupy\n\n\n\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Na Českomoravské vrchovině stojí nedaleko stejnojmenné osady&nbsp;<span style=\"font-weight: bold;\">gotický hrad Pernštejn</span>, jeden z nejvýznamnějších moravských hradů."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"Pernštejn byl vystavěn&nbsp;<span style=\"font-weight: bold;\">v polovině 13. století</span>&nbsp;a až do 16. století přestavován, přesto si ale do značné míry zachoval svoji&nbsp;<span style=\"font-weight: bold;\">původní obrannou funkci</span>.&nbsp;<span style=\"font-weight: bold;\">Pernštejnům</span>&nbsp;hrad patřil až do konce 16. století, pak často střídal majitele. Dnes je v majetku státu a je přístupný veřejnosti."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"Na návštěvníky na Pernštejně čeká&nbsp;<span style=\"font-weight: bold;\">prohlídkový okruh</span>, který je zavede do všech důležitých&nbsp;<span style=\"font-weight: bold;\">interiérů</span>&nbsp;a seznámí je s<span style=\"font-weight: bold;\">&nbsp;historií hradu</span>&nbsp;od jeho počátků až do současnosti. K vidění jsou&nbsp;<span style=\"font-weight: bold;\">monumentální Rytířský sál</span>, kaple, ložnice s původními kachlovými kamny i bohatě zásobená knihovna."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"K dispozici je&nbsp;<span style=\"font-weight: bold;\">zkrácený okruh&nbsp;</span>a také&nbsp;<span style=\"font-weight: bold;\">speciální okruh pro děti</span>, nádvoří si zájemci mohou prohlédnout bez průvodce"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"font-weight: bold;\">Terasová zahrada a krajinářský park</span>, který hrad obklopují, byly založeny v 18. a 19. století a dodnes se tady zachovaly bazén a čínský pavilon.\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/2124/5db7e059d56c420297fd65859eeb51a8_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/2124/5db7e059d56c420297fd65859eeb51a8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/5db7e059d56c420297fd65859eeb51a8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/5db7e059d56c420297fd65859eeb51a8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/5db7e059d56c420297fd65859eeb51a8_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"koupani"} style={{"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left" content={"Koupání"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"bj5ye8712o9"} style={{"paddingTop":37,"paddingBottom":42}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"Novoměstské lázně – 7 km od chalupy\n\n\n\n\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"V hlavní části lázní kromě 25 m bazénu se čtyřmi drahami naleznete také&nbsp;<span style=\"font-weight: bold;\">relaxační bazén s masážní lavicí, chrliči a vzduchováním nebo vířivku pro deset osob</span>. Příjemnou zprávou je, že relaxační bazén i vířivka, stejně jako dětský bazének se skluzavkou&nbsp;mají slanou vodu, která není tak agresivní na oči.&nbsp;"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"I teplota vody je velmi příjemná, obzvlášť pro zimní návštěvu. V plaveckém bazénu byste naměřili necelých 28 °C, zatímco relaxační a dětský bazén se pohybuje okolo 30 °C a&nbsp;<span style=\"font-weight: bold;\">ve vířivce se ohřejete téměř v 36 °C</span>.&nbsp;"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"Vodními hrátkami to ovšem ve městských lázních rozhodně nekončí. K návštěvě láká také&nbsp;<span style=\"font-weight: bold;\">saunový svět s Finskou a Aroma saunou</span>, které mají kapacitu shodně 14 osob. Kromě toho ve wellness části lázní najdete také&nbsp;<span style=\"font-weight: bold;\">parní saunu, Kneipovu lázeň a samozřejmě také ochlazovací studnu a klidovou odpočívárnu</span>. Za příplatek jsou k dispozici také relaxační koupele a masáže."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/25609/dc1a1c69aa1b4a18bb9d4c63d0660b9a_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/25609/dc1a1c69aa1b4a18bb9d4c63d0660b9a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25609/dc1a1c69aa1b4a18bb9d4c63d0660b9a_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60" name={"bj5ye8712o9"} style={{"paddingTop":0}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"Skalský rybník – 2,5 km od chalupy\n\n\n\n\n\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Skalský rybník je ideálním místem ke koupání. Pláže jsou travnaté. Poblíž jsou tenisové kurty.&nbsp;&nbsp;"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/25609/bd4da25870c64ff8b43d0acdf58c12e9_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":796}} srcSet={"https://cdn.swbpg.com/t/25609/bd4da25870c64ff8b43d0acdf58c12e9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25609/bd4da25870c64ff8b43d0acdf58c12e9_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"Zuberský rybník – 4 km od chalupy\n\n\n\n\n\n\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Klidné místo ke koupání. Vstup do vody pozvolný, písčitý. U blízkého parkoviště je stánek s občerstvením.\n"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/25609/025f8c40ddbc4695b6cde312f0ff42fe_s=350x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":795}} srcSet={"https://cdn.swbpg.com/t/25609/025f8c40ddbc4695b6cde312f0ff42fe_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--25" name={"paticka"} style={{"backgroundColor":"rgba(21,21,22,1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2" anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Subtitle className="subtitle-box fs--20 w--600" content={"<span style=\"color: var(--color-custom-2);\">Chalupavojtechov.cz</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-2);\">Vojtěchov č. ev. 107<br>593 01 Lísek<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--20 lh--16" content={"<br><span style=\"color: rgb(201, 201, 201);\">+420</span> <span style=\"color: var(--color-custom-2);\">774 344 014</span><br><a href=\"mailto:nikola@chalupavojtechov.cz?subject=Zpr%C3%A1va+z+webu\"><span style=\"color: rgb(201, 201, 201);\">nikola</span><span style=\"color: var(--color-custom-2);\">@chalupavojtechov.cz</span></a><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}